import React from 'react';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";


//NOTE - Components
import Hero from './hero';
import LogoBanner from './logo-banner';
import Mission from './mission';
import ASO from './ASO';
import Value from './value';
import Team from '../about/team';
function Home() {
    const { setPathPageView, setTrackPageView } = useTrackingCode();
    setPathPageView("/");
    setTrackPageView();
    
    return (
        <div className='w-full snap-y snap-mandatory'>
            <Hero />
            <LogoBanner />
            <Mission />
            <ASO />
            <Value />
        </div>
    );
}

export default Home;