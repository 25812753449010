import React from "react";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";



import Hero from "./hero";
import ReviewsCarousel from "./reviewsCarousel";
import Beliefs from "./beliefs";
import Team from "./team";

const About = () => {
    const { setPathPageView, setTrackPageView } = useTrackingCode();
    setPathPageView("/about");
    setTrackPageView();
    return (
        <div>
            <Hero />
            <ReviewsCarousel />
            <Beliefs />
            <Team />
        </div>
    )
}

export default About;