import August from "./images/webp/August-3.webp"
import Jeff from "./images/webp/Jeff1.webp";
import Shaelyn from "./images/webp/Shae.webp";
import Kamari from "./images/webp/Kamari.webp";
import Sabrina from "./images/webp/Sabrina1.webp";
import hannah from "./images/webp/hannahE.webp"
import Erica from './images/webp/EricaL.webp';
import Ethan from './images/webp/ethan-3.webp';
import Megan from './images/webp/MC.webp';
import Jesse from './images/webp/jesse.webp';

export const teamMembers = [
    {
        id: 101,
        name: "August Shah",
        title: "Founder & President",
        bio: "August is the founder of Partnered Management Group, bringing with him over 6+ years of experience as a Software Engineer and Technical Project Manager. Throughout his career, he has held key positions such as Director of Operations for an aviation company, Director of Product for a social media company, and Director of Technology for multiple digital agencies. With his strong data-driven and analytical skills, August ensures that every project is executed effectively and within budget. His diverse background and expertise allow him to approach each venture with a unique perspective, bringing valuable insights to the table. August's passion for delivering exceptional results is evident in his meticulous planning and strategic execution. He thrives on optimizing project outcomes, leveraging data insights to drive success. His ability to navigate complex challenges and keep projects on track has earned him a reputation for reliability and professionalism",
        image: August,
    },
    {
        id: 102,
        name: "Jeff MacBride - (PMP, LSS, CSM)",
        title: "Managing Director of Operations",
        bio: "Jeff is an accomplished and dedicated leader with an impressive tenure of over 15 years in project and operations management. Throughout his career, he has consistently demonstrated his ability to successfully lead cross-functional teams and deliver strategic technology solutions to a diverse range of clients. With a keen focus on customer satisfaction, Jeff has overseen critical aspects of projects, including system and software design, development, integration, and testing, across multiple stages of the project lifecycle. His extensive experience encompasses both waterfall and agile methodologies.  Jeff has been instrumental in establishing project management offices for small companies experiencing rapid growth, effectively implementing structure and process improvements to enhance overall efficiency and productivity. Outside of his professional endeavors, Jeff finds enjoyment in a variety of activities. He appreciates the art of cinema, loves to explore new destinations through travel, prioritizes physical fitness through regular workouts, and cherishes quality time with his loved ones.",
        image: Jeff,
    },
    {
        id: 103,
        name: "Erica Lester - (PMP)",
        title: "Director of Project Management",
        bio: "Erica is a seasoned professional with a wealth of experience in Agile and Kanban methodologies. With over a decade of expertise, Erica has steered projects of all sizes, ranging from corporate and government initiatives with teams exceeding 60 members and budgets of $50 million.  Her unique ability to bridge the gap between technical and business stakeholders sets her apart. Erica's forte lies in managing complex projects while ensuring client satisfaction.  As a Certified Business Analyst and Project Management Professional, Erica boasts a remarkable 20-year track record of identifying client needs, meticulously documenting requirements, and executing project plans to successful fruition.",
        image: Erica,
    },
    {
        id: 104,
        name: "Shaelyn Ventrano - (PMP)",
        title: "Senior Project Manager",
        bio: "Shaelyn is a seasoned professional with more than 10 years of project management experience in a diverse range of fields, including creative design, website development, e-learning, e-commerce, and small business social media planning. Her expertise extends to developing and implementing Standard Operating Procedures (SOPs), as well as outlining comprehensive strategies to drive success. With an MS in Purchasing, Procurement/Acquisitions, and Contract Management, as well as a BS in Business Administration, Shaelyn possesses a strong educational foundation that complements her practical experience. Moreover, her commitment to professional growth is exemplified by her current PMP certification, which underscores her proficiency in project management best practices. Throughout her career, Shaelyn has consistently demonstrated her ability to lead teams, effectively communicate with stakeholders, and deliver successful outcomes. Her meticulous attention to detail, combined with her strategic mindset, enables her to navigate complex projects with ease, ensuring that objectives are met and stakeholders' expectations are exceeded.",
        image: Shaelyn,
    },
  
    {
        id: 105,
        name: "Kamari Patrick - (PMI - ACP)",
        title: "Senior Project Manager",
        bio: "Kamari is a seasoned expert in digital product development leveraging agile methodologies across the entire product lifecycle. With over 10 years of project management experience, she excels in gathering and prioritizing customer requirements, defining product user flows, and collaborating closely with engineering teams to outline product features. Kamari takes a meticulous approach to benchmarking existing solutions, surveying prospective users, crafting user stories, developing wireframes, conducting usability studies, and finalizing feature requirements for communication to design and engineering teams. Having successfully led teams of up to 10+ engineers. She served as a technical product owner for an EdTech SaaS company, bringing a wealth of experience to the table. Some notable successes in Kamari's portfolio include the launch of a CMS Drupal implementation for VA VBA Offices. Additionally, Kamari led the audit and redesign of a web-based facility search tool to comply with WCAG accessibility standards. With a proven history of delivering successful digital solutions, Kamari is poised to bring innovation and expertise to any project.",
        image: Kamari,
    },
    {
        id: 106,
        name: "Sabrina Ventrano - (PMP)",
        title: "Project Manager",
        bio: "Sabrina is a highly experienced digital project manager with over 4 years of expertise in the field. She specializes in creating standard operating procedures (SOPs), managing budgets, and overseeing construction projects. With her strong analytical and organizational skills, Sabrina ensures that each project is thoroughly scoped and delivered on time. Sabrina's educational background includes a Bachelor's degree in Marketing/Communication and Social Interaction, which has equipped her with a solid foundation in marketing principles and effective communication strategies. In addition, she holds a current certification as a Project Management Professional (PMP), demonstrating her commitment to staying updated with industry best practices. In her role, Sabrina consistently excels at navigating complex projects, working closely with stakeholders to drive successful outcomes. Her meticulous attention to detail and ability to effectively scope projects contribute to the overall success of each initiative.",
        image: Sabrina,
    },
    {
        id: 107,
        name: "Ethan Hayes",
        title: "Tech Lead",
        bio: "Ethan, a Dallas native, brings a unique blend of military service and technological expertise to the PMG team. Following five impactful years as a Network Administrator in the Army, during which he deployed and traveled extensively, Ethan transitioned to civilian life with a focus on software engineering. His academic achievements include earning an AAS in Computer Systems Networking and Telecommunications, an AAS in Computer and Information Sciences and Support Services, and a BASc in Robotics Engineering with a secondary major in Artificial Intelligence Architecture and Theory. These degrees have provided Ethan with a strong foundation in networking and software development. In his daily pursuits, Ethan explores various tech-related activities, from experimenting in the kitchen to crafting custom-made pots for his plant collection using his trusty 3D printer. Proficient in full-stack programming, Ethan seamlessly navigates front-end and back-end development and possesses expertise in multiple coding languages. Additionally, he maintains an interest in game development, adding a creative dimension to his skill set.  Ethan's military service, coupled with his academic and practical experiences, has instilled in him a deep sense of adaptability, resilience, and a relentless pursuit of excellence. With each project, he brings a unique combination of technical expertise, creativity, and a commitment to growth and innovation.",
        image: Ethan,
    },
    {
        id: 108,
        name: "Megan Carr",
        title: "Full Stack Engineer",
        bio: "Megan started web development 10 years ago. Her first experience with Shopify was building her eCommerce site – JoinCleanAir. After showing her website to friends and colleagues, she began getting requests to design and develop similar websites for clients. Megan has built over 100+ sites using WordPress and Shopify. Throughout these projects she has become confident in building any site with any requirements. This has enabled her to focus on gaining experience in other CMS such as, BigCommerce, NopCommerce, SquareSpace, Wix, and Webflow.",
        image: Megan,
    },
    {
        id: 109,
        name: "Jesse Fin",
        title: "Full Stack Engineer",
        bio: "Jesse is a versatile Full Stack/DevOps Engineer with a wealth of experience in both backend and frontend technologies. With a keen eye for detail and a knack for managing multiple projects simultaneously, Jesse excels in assessing appropriate technology stacks and orchestrating server management in a DevOps capacity. He is known for his collaborative spirit, team-oriented approach, and unwavering commitment to continuous learning. Jesse's proficiency spans a range of languages and technologies, including JavaScript, TypeScript, Python 3, PHP8, and Java (Android), among others. With a solid foundation in frontend development frameworks like Vue and React, coupled with expertise in backend technologies such as PostgreSQL, MySQL, and MongoDB, Jesse brings a holistic skill set to every project. Whether it's crafting intuitive user interfaces or optimizing server infrastructure, he is dedicated to delivering top-notch solutions that exceed expectations.",
        image: Jesse,
    },
    {
        id: 110,
        name: "Hannah Erskine",
        title: "UI/UX Designer",
        bio: "Hannah is a dynamic Digital Product Designer specializing in crafting exceptional user experiences. With a background in designing and developing responsive web apps for diverse industries, Hannah brings expertise in collaborating with high-profile clients such as Live Nation and JAJA Tequila. As the Founder of Stendahl Ceramics, she has curated intuitive eCommerce experiences and managed client projects from concept to completion. Hannah holds a Master of Science in Social Entrepreneurship from the University of Southern California and a Bachelor of Arts in Sociology and Politics from Occidental College.",
        image: hannah,
    }
]