import React from "react";
import {Link} from "react-router-dom";

const Mission = () => {
    return (
        <div className="container mx-auto text-blue text-left pb-12 pt-24 px-6 flex flex-col gap-10 font-bold lg:py-36 lg:w-4/5">
            <h2 className="text-5xl pointer-events-none">Our Expertise</h2>
            <p className="text-lg leading-8 pointer-events-none">By joining the Partnered Program you gain access to our fractional project management, website design, and website development services led by our team of PMP certified project managers with over 30 years of experience.  <span className="text-gold">Our team specializes in industries such as Information Technology, Digital Marketing, Graphic Design, Ecommerce, and Transportation</span>. By leveraging data-driven decsion making through our Work Smarter strategy, our experts are equipped with custom software to tackle even the most complex projects. They will help you achieve successful outcomes through effective project management and innovative digital solutions.  Whether you're starting a new project or seeking to optimize existing ones, our team is dedicated to elevating your business.</p>
            <Link to="/about" className="w-full mt-5 text-blue bg-transparent border border-solid border-blue text-center py-3 rounded-3xl hover:text-white hover:bg-gold hover:border-gold md:w-48">Why PMG?</Link>
        </div>   
    )
}


export default Mission;