export const reviews = [
    {
        id: 1,
        reviewContent: "PMG has really come through for us. When we needed additional project management support, they sent two seasoned professionals our way. We couldn't be happier with each of them! It's been more cost effective than full-time employees, which certainly helps our bottom line. We highly recommend working with PMG. They have great communication and great people!",
    },
    {
        id: 2,
        reviewContent: "Communication and response from the PMG team was prompt and consistent. It felt like a team which is very important as we work with vendors.  Jeff from the PMG team displayed technical knowledge and provided guidance to the team.",
    },
    {
        id: 3,
        reviewContent: "Shaelyn corrected what was not going very well in our weekly then bi weekly status calls. We moved more to a standup type nature rather than listening to the project plan be read thru.  Once Shaelyn took over that role and face of the project it was smoother.",    
    },
    {
        id: 4,
        reviewContent: "Jeff is a very helpful project manager and has had a lot of ideas to help improve our process along the way. He's got a go-getter kind of attitude and knows how to go from idea to execution.",
    },
    {
        id: 5,
        reviewContent: "August is an excellent technical project manager. He is calm, brings order to the process and his technical background is a real game changer when it comes to working with development teams. I would highly recommend him for any technical project.",
    },
  
    {
        id: 6,
        reviewContent: "August showed a great deal of understanding and capability as he migrated our site to a new hosting platform. His communication through the process was wonderful, giving updates unprompted. He was thorough in his testing, giving us the confidence that we were launching the best site possible.",
    },
]