import React from "react";
const ASO = () => {
    return(
        <div style={{ width: "100%", marginTop: 10, marginBottom: 70 }}>
          <style scoped=""
            dangerouslySetInnerHTML={{
              __html: "\n .brandpush-logo-container-item { height: auto; width: 100%; position: relative; padding: 25px 50px; } \n .brandpush-vertical-center { height: inherit; display: flex; align-content: center; flex-wrap: wrap; flex-direction: row; justify-content: center; align-content: center; align-items: center; } \n .brandpush-vertical-center img.brandpush-news-logo{ max-width: 100px; max-height: 66px; min-width: 50px; min-height: 14px; height: auto; width: auto; margin: 7px 10px; overflow: visible; } \n .brandpush-trust-badge, .brandpush-title, .brandpush-footer{ font-family: sans-serif !important; } \n .brandpush-title:before, .brandpush-title:after { content: ''; flex: 1 1; border-bottom: 1px solid #d8d8d8; margin: auto; width: 27%; display: block; position: relative; } \n .brandpush-title:before{ margin-left: 30px; top:12px; } \n .brandpush-title:after{ margin-right: 30px; top: -13px; } \n @media screen and (min-width: 585px) { \n .brandpush-logo-container{padding: 0 20px;} } \n @media screen and (max-width: 584px) { \n .brandpush-news-logo {max-width:70px !important;} \n .brandpush-title {font-size: 15px;top: -5px;letter-spacing: 6px;} \n .brandpush-title:before, .brandpush-title:after{border-bottom:none !important;} \n .brandpush-leaf{display:none !important;} } \n @media screen and (max-width: 340px) { \n .brandpush-title-hr {display:none !important;} \n .brandpush-title {font-size:14px;padding:0 !important;} \n .brandpush-footer {font-size:11px !important;margin:20px 0 25px 0 !important;letter-spacing: 2px !important;} \n .brandpush-news-logo{max-width:50px !important;} } \n .brandpush-logo-container{ text-align: center; margin: 0 auto 0 auto; display: flex; align-content: center; justify-content: space-between; align-items: center; flex-wrap: nowrap; flex-direction: row; } \n .brandpush-newslogos { display:inline-block; position: relative; }",
            }}
          />
          <div id="brandpush-trust-badge" style={{ position: "relative", width: "100%", background: "#fff", borderRadius: 10, minHeight: 180, marginLeft: "auto", marginRight: "auto", paddingBottom: 12 }}>
            <div style={{ textAlign: "center", padding: "0px 5px 10px 5px", fontSize: 18, fontFamily: "sans-serif", fontWeight: 600, letterSpacing: 8, lineHeight: 1.3}}>
                <div style={{ height: 58 }}>
                    <span className="brandpush-title" style={{ zIndex: 1, position: "relative", padding: "0 20px", margin: 0, color:"#0e0e0e" }}>AS SEEN ON</span>
                </div>
          <div className="brandpush-logo-container">
            <img className="brandpush-leaf"  alt="Trust Reef" style={{ position: "absolute", height: 110, left: 25, margin: 0, padding: 0, zIndex: 0, opacity: 0.2 }} src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fb10293-8878-4ce5-5496-cef376fe9300/public" />
            <img className="brandpush-leaf" alt="Trust Reef" style={{ position: "absolute", height: 110, right: 25, margin: 0, padding:0, zIndex: 0, opacity: 0.2, WebkitTransform: "scaleX(-1)", transform: "scaleX(-1)" }} src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fb10293-8878-4ce5-5496-cef376fe9300/public" />
            <div className="brandpush-logo-container-item">
              <div className="brandpush-vertical-center">
                  <img alt="Featured on Suncoast News Network" className="brandpush-news-logo" style={{ marginRight: 14 }} src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/064e355d-7093-4b83-a573-d01a19b47500/public" />
                  <img alt="Featured on FOX 40" className="brandpush-news-logo" style={{ marginRight: 14 }} src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fc4af63-e906-4112-d141-d504c6ea5c00/public" />
                  <img alt="Featured on NCN" className="brandpush-news-logo" style={{ marginRight: 14 }} src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/31f90197-ad2e-4772-b420-56e60b9b4a00/public" />
                  <img alt="Featured on Digital Journal" className="brandpush-news-logo" style={{ marginRight: 14 }} src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/8baa0efa-aec5-4538-f2ad-614438078c00/public" />
                  <img alt="Featured on AZ Central" className="brandpush-news-logo" style={{ marginRight: 14 }} src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/6ae35cac-abc5-44af-4dec-e75c4c9d8d00/public" />
              </div>
            </div>
          </div>
          <div style={{ marginTop: 0, fontFamily: "sans-serif !important;"}}>
            <span className="brandpush-footer" style={{ textAlign: "center", padding: "0 20px", fontSize: 13, fontFamily: "sans-serif", fontWeight: 600, letterSpacing: 3, position: "relative", width: "100%", margin: "0 0 8px 0", display: "inline-block", color: "#a5a5a5" }}>AND OVER 400 NEWS SITES</span>
            <div style={{ color: "#717171", fontSize: 10, letterSpacing: 0, height: 15, margin: "3px 0 0 0", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                <img style={{ width: 12, margin: "0 3px 0 0" }} src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/5e5b9b47-6288-4640-2b2a-cd2459605c00/public" />
                <span style={{ fontFamily: "sans-serif !important" }}>Verified by <a style={{ color: "#717171" }} href="https://www.brandpush.co?utm_source=Client+Referral&utm_medium=Trust+Badge&utm_campaign=Trust+Badge&utm_content=1688255634410" target="_Blank">BrandPush.co</a></span>
            </div>
          </div>
            </div>
        </div>
      </div>
    )
};

export default ASO;
